<template>
  <div>
    <b-custom-spinner :is_progress="is_progress"></b-custom-spinner>
    <b-card>
      <b-card-text>
        <b-form-group>
          <v-select
            @input="updateSelected"
            v-model="line_group"
            :options="line_groups"
            :placeholder="lang('t_lineGroup')"
            :reduce="(option) => option"
            label="display_name"
            value="internal_name"
          >
            <template v-slot:option="option">
              <!-- <span :class="'UserIcon'"></span> -->
              <span v-if="option.internal_name == 'new_record'">
                <feather-icon icon="PlusIcon" />
                {{ lang("t_addNewRecord") }}
              </span>
              <span v-else>{{ option.display_name }}</span>
            </template>
          </v-select>
        </b-form-group>

        <b-form-input
          v-model="line_group.display_name"
          :placeholder="lang('t_displayName')"
          id="linegroup"
          trim
          class="mt-50"
        />

        <b-button
          @click="set_line_group"
          class="float-right ml-1 mt-1"
          variant="primary"
          >{{ lang("t_save") }}</b-button
        >

        <b-button
          @click="clearAll"
          v-if="line_group.internal_name != 'new_record'"
          class="float-right mt-1"
          variant="danger"
          >{{ lang("t_delete") }}</b-button
        >
      </b-card-text>
    </b-card>
    <b-card style="height: 53vh">
      <b-row align-v="center">
        <b-col cols="5">
          <b-card
            border-variant="primary"
            :title="lang('t_selectableLines')"
            class="business-card"
            style="min-height: 45vh; max-height: 45vh; overflow-y: auto"
          >
            <b-card-text class="mb-1">
              <b-form-input v-model="search1" :placeholder="lang('t_search')">
              </b-form-input>
            </b-card-text>
            <div class="business-items">
              <div
                v-for="(lineGroupItem, i) in filterSearch1"
                :key="i"
                class="business-item"
              >
                <div class="d-flex align-items-center justify-content-between">
                  <b-form-checkbox v-model="lineGroupItem.checked">
                    {{ lineGroupItem.display_name }}
                  </b-form-checkbox>
                </div>
              </div>
            </div>
          </b-card>
        </b-col>

        <b-col cols="2" style="min-height: 200px">
          <b-row>
            <b-col cols="12" class="text-center mt-1">
              <b-button
                @click="add_line_to_group"
                variant="primary"
                class="btn-icon rounded-circle"
              >
                <feather-icon icon="ArrowRightIcon" />
              </b-button>
            </b-col>
          </b-row>
          <b-row class="text-center mt-1">
            <b-col cols="12">
              <b-button
                @click="remove_line_from_group"
                variant="primary"
                class="btn-icon rounded-circle"
              >
                <feather-icon icon="ArrowLeftIcon" />
              </b-button>
            </b-col>
          </b-row>
        </b-col>

        <b-col cols="5">
          <b-card
            border-variant="primary"
            :title="lang('t_selectedLines')"
            class="business-card"
            style="min-height: 45vh; max-height: 45vh; overflow-y: auto"
          >
            <b-card-text class="mb-1">
              <b-form-input v-model="search2" :placeholder="lang('t_search')">
              </b-form-input>
            </b-card-text>
            <div class="business-items">
              <div
                v-for="(lineGroupItem2, i) in filterSearch2"
                :key="i"
                class="business-item"
              >
                <div class="d-flex align-items-center justify-content-between">
                  <b-form-checkbox v-model="lineGroupItem2.checked">
                    {{ lineGroupItem2.display_name }}
                  </b-form-checkbox>
                </div>
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import vSelect from 'vue-select'

import { VBModal, BModal, BButton, BRow, BCol, BBadge, BFormInput, BFormGroup, BCard, BCardText, BLink, BForm, BFormCheckbox, } from 'bootstrap-vue'

export default {
  data() {
    return {
      lang: _l(window.localStorage.getItem('language') || "tr"),
      is_progress: false,
      selectable_lines: [],
      tmp_potential_lines: [],
      unselectable_lines: [],
      tmp_unpotential_lines: [],
      line_groups: [],
      line_list: [],
      line_group: {
        internal_name: 'new_record',
        display_name: '',
        lines: [],
      },
      selected_item: {},
      search1: '',
      search2: '',

    }
  },
  components: {
    BModal,
    BButton,
    BRow,
    BCol,
    BBadge,
    vSelect,
    BCard,
    BCardText,
    BLink,
    BFormInput,
    BFormGroup,
    BForm,
    BFormCheckbox,
  },
  directives: {
    'b-modal': VBModal,
  },
  mounted: function () {
    this.is_progress = true;
    this.get_line();
    this.get_line_groups();
    this.create_new();
    this.is_progress = false;
  },
  computed: {
    filterSearch1() {
      if (this.search1 !== '') {
        return this.tmp_potential_lines.filter((e) => {
          return e.display_name.match(this.search1);
        });
      }

      return this.tmp_potential_lines;
    },
    filterSearch2() {
      if (this.search2 !== '') {
        return this.tmp_unpotential_lines.filter((e) => {
          return e.display_name.match(this.search2);
        });
      }

      return this.tmp_unpotential_lines;
    }
  },
  methods: {
    add_line_to_group() {
      this.selectable_lines = this.tmp_potential_lines.filter(e => e.checked == true);
      console.log(this.selectable_lines);

      this.selectable_lines.map((e) => {
        this.tmp_unpotential_lines.push(e);
        this.removeA(this.tmp_potential_lines, e);
      });

      this.tmp_potential_lines = this.tmp_potential_lines.map(obj => ({ ...obj, checked: false }))
      this.tmp_unpotential_lines = this.tmp_unpotential_lines.map(obj => ({ ...obj, checked: false }))

      // this.selectable_lines = [];
      // this.unselectable_lines = [];
    },
    remove_line_from_group() {
      this.unselectable_lines = this.tmp_unpotential_lines.filter(e => e.checked == true);

      this.unselectable_lines.map((e) => {
        this.tmp_potential_lines.push(e);
        this.removeA(this.tmp_unpotential_lines, e);
      });
      this.tmp_potential_lines = this.tmp_potential_lines.map(obj => ({ ...obj, checked: false }))
      this.tmp_unpotential_lines = this.tmp_unpotential_lines.map(obj => ({ ...obj, checked: false }))
    },
    removeA(arr) {
      var what,
        a = arguments,
        L = a.length,
        ax;
      while (L > 1 && arr.length) {
        what = a[--L];
        while ((ax = arr.indexOf(what)) !== -1) {
          arr.splice(ax, 1);
        }
      }
      return arr;
    },

    get_line_groups: async function () {
      this.is_progress = true;
      var response = (
        await this.$http_in.get(`/smgr/v1/LineGroup`)
      ).data;

      this.line_groups = [{
        internal_name: 'new_record',
        display_name: '',
        lines: [],
      }, ...response];
      console.log(" this.line_groups", this.line_groups);
      this.is_progress = false;
    },
    get_line: async function () {
      var response = (
        await this.$http_in.get(`/smgr/v1/Line`)
      ).data;

      response = response.map(obj => ({ ...obj, checked: false }))

      this.tmp_potential_lines = [...response];
      this.line_list = [...response];
    },
    editItem(item) {
      console.log('this is from parent:', item);
      this.selected_item = item;
      console.log('this.selected_item', this.selected_item);

      this.edit_modal = true;
      console.log(' this.modal', this.edit_modal);

    },
    delete_line_group: async function () {
      this.is_progress = true;
      var response = (
        await this.$http_in.delete(`/smgr/v1/LineGroup/${this.line_group.internal_name}`)
      ).data;
      this.is_progress = false;
      if (response != null || response != undefined) {
        this.$swal({
          icon: 'success',
          title: this.lang('t_processSuccess'),
          text: this.lang('t_customersHaveBeenSuccessfullyDeleted'),
          confirmButtonText: this.lang('t_ok'),
          customClass: {
            confirmButton: 'btn btn-success',
          },
        });
        this.create_new();
        this.get_line_groups();
      }
    },
    clearAll() {
      this.$swal({
        title: this.lang('t_deleteRecord'),
        text: this.lang('t_areYouSureYouWantToDeleteTheSelectedRecord'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.lang('t_yes'),
        cancelButtonText: this.lang('t_cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',

        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.delete_line_group();
        }
      })
      // console.log("line_group", this.line_group);
      // var _list = this.line_group.linegroup_object.slice();
      // this.list = _list;
    },
    set_line_group: async function () {
      if (this.line_group.display_name == '' || this.line_group.display_name == null) {
        this.$swal({
          icon: 'warning',
          title: '',
          text: globalThis._lang("t_pleaseEnterTheLineGroupName"),
          confirmButtonText: this.lang('t_ok'),
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        });
        return;
      }
      if (this.tmp_unpotential_lines.length == 0) {
        this.$swal({
          icon: 'warning',
          title: '',
          text: globalThis._lang("t_youNeedToPickALineToBeAbleToCreateLineGroup"),
          confirmButtonText: this.lang('t_ok'),
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        });
        return;
      }

      this.line_group.lines = this.tmp_unpotential_lines;
      this.is_progress = true;
      var response;
      if (!this.edit) {
        response = (
          await this.$http_in.post(
            `/smgr/v1/LineGroup`,
            { data: JSON.stringify(this.line_group) }
          )
        ).data;
      } else {
        response = (
          await this.$http_in.put(
            `/smgr/v1/LineGroup`,
            { data: JSON.stringify(this.line_group) }
          )
        ).data;
      }
      this.is_progress = false;
      if (response != null || response != undefined) {
        if (response.command == 'UPDATE') {
          this.$swal({
            icon: 'success',
            title: this.lang('t_processSuccess'),
            text: `${this.line_group.display_name} hat grubu başarılı bir şekilde güncellendi.`,
            confirmButtonText: this.lang('t_ok'),
            customClass: {
              confirmButton: 'btn btn-success',
            },
          });
        } else {
          this.$swal({
            icon: 'success',
            title: this.lang('t_processSuccess'),
            text: '',
            confirmButtonText: this.lang('t_ok'),
            customClass: {
              confirmButton: 'btn btn-success',
            },
          });
          this.create_new();
        }
        this.get_line_groups();
      }
    },
    updateSelected(selectedItem) {
      console.log("updateSelected", selectedItem);


      if (selectedItem.internal_name == 'new_record') {
        this.tmp_potential_lines = [...this.line_list];
        this.tmp_unpotential_lines = [];
        this.selectable_lines = [];
        this.unselectable_lines = [];
        this.edit = false;
        for (const item of this.tmp_potential_lines) {
          item.checked = false;
        }
        for (const item of this.tmp_unpotential_lines) {
          item.checked = false;
        }
        return;
      }
      this.edit = true;
      this.tmp_potential_lines = [...this.line_list];
      this.tmp_unpotential_lines = [];
      this.selectable_lines = [];
      this.unselectable_lines = [];
      var _lines = selectedItem.lines;
      for (const item of this.tmp_potential_lines) {
        item.checked = false;
      }
      for (const item of this.tmp_unpotential_lines) {
        item.checked = false;
      }

      for (const row of _lines) {
        var _row = this.tmp_potential_lines.find((e) => e.internal_name == row.internal_name);
        if (_row != undefined) {
          _row.checked = true;
        }
      }
      this.add_line_to_group();
    },
    create_new() {
      this.edit = false;
      this.line_group = {
        internal_name: 'new_record',
        display_name: '',
        lines: [],
      };

      this.tmp_potential_lines = [...this.line_list];
      this.tmp_unpotential_lines = [];
      this.selectable_lines = [];
      this.unselectable_lines = [];
      for (const item of this.tmp_potential_lines) {
        item.checked = false;
      }
      for (const item of this.tmp_unpotential_lines) {
        item.checked = false;
      }
    },
  },

}

</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
