<template>
  <div>
    <b-custom-spinner :is_progress="is_progress"></b-custom-spinner>
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ lang("t_show") }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ lang("t_entries") }}</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                :placeholder="lang('t_search')"
              />
              <b-button
                variant="primary"
                @click="
                  modal_record_details = true;
                  isEdit = false;
                "
              >
                <span class="text-nowrap">{{ lang("t_addRecord") }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        style="min-height: 300px"
        ref="refUserListTable"
        class="position-relative"
        :items="records"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="lang('t_noRecord')"
        :sort-desc.sync="isSortDirDesc"
        :filter="searchQuery"
      >
        <!-- Column: User -->
        <template #cell(user)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.avatar"
                :text="avatarText(data.item.fullName)"
                :variant="`light-${resolveUserRoleVariant(data.item.role)}`"
                :to="{ name: 'apps-users-view', params: { id: data.item.id } }"
              />
            </template>
            <b-link
              :to="{ name: 'apps-users-view', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.fullName }}
            </b-link>
            <small class="text-muted">@{{ data.item.username }}</small>
          </b-media>
        </template>

        <!-- Column: Role -->
        <template #cell(role)="data">
          <div class="text-nowrap">
            <feather-icon
              :icon="resolveUserRoleIcon(data.item.role)"
              size="18"
              class="mr-50"
              :class="`text-${resolveUserRoleVariant(data.item.role)}`"
            />
            <span class="align-text-top text-capitalize">{{
              data.item.role
            }}</span>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ data.item.status }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item @click="updateSelected(data.item)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">{{ lang("t_edit") }}</span>
            </b-dropdown-item>

            <b-dropdown-item @click="confirmText(data.item)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">{{ lang("t_delete") }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >{{ lang("t_showing") }} {{ records.length }}
              {{ lang("t_entries") }}</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-modal
      no-close-on-esc
      no-close-on-backdrop
      :title="isEdit ? lang('t_edit') : lang('t_add')"
      centered
      v-model="modal_record_details"
      :ok-title="lang('t_save')"
      :cancel-title="lang('t_cancel')"
      @ok="add_record"
    >
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- timeConditionName -->
          <validation-provider
            #default="validationContext"
            name="timeConditionName"
            rules="required"
          >
            <b-form-group
              :label="lang('t_timeConditionName')"
              label-for="timeConditionName"
            >
              <b-form-input
                id="timeConditionName"
                v-model="currentData.display_name"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- startDay -->
          <validation-provider
            #default="validationContext"
            name="startDay"
            rules="required"
          >
            <b-form-group :label="lang('t_startDay')" label-for="startDay">
              <v-select
                v-model="currentData.start_day"
                :options="days"
                :placeholder="lang('t_startDay')"
                :reduce="(val) => val"
                label="value"
              >
              </v-select>

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- endDay -->
          <validation-provider
            #default="validationContext"
            name="startDay"
            rules="required"
          >
            <b-form-group :label="lang('t_endDay')" label-for="endDay">
              <v-select
                v-model="currentData.end_day"
                :options="days"
                :placeholder="lang('t_endDay')"
                :reduce="(val) => val"
                value="key"
                label="value"
              >
              </v-select>

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- startTime -->
          <validation-provider
            #default="validationContext"
            name="startTime"
            rules="required"
          >
            <b-form-group :label="lang('t_startTime')" label-for="startTime">
              <!--
             <v-select
                v-model="currentData.startTime"
                :options="dayOptions"
                :placeholder="lang('t_startTime')"
                :reduce="val => val.label"
              >
              </v-select>
              -->

              <b-form-timepicker v-model="currentData.start_time" locale="tr" />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- endTime -->
          <validation-provider
            #default="validationContext"
            name="endTime"
            rules="required"
          >
            <b-form-group :label="lang('t_endTime')" label-for="endTime">
              <!--
             <v-select
                v-model="currentData.endTime"
                :options="monthOptions"
                :placeholder="lang('t_endTime')"
                :reduce="val => val.label"
              >
              </v-select>
              -->

              <b-form-timepicker v-model="currentData.end_time" locale="tr" />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- monthStart -->
          <validation-provider
            #default="validationContext"
            name="monthStart"
            rules="required"
          >
            <b-form-group :label="lang('t_monthStart')" label-for="monthStart">
              <v-select
                v-model="currentData.month_start"
                :options="months"
                :placeholder="lang('t_monthStart')"
                :reduce="(val) => val.internal_name"
                value="internal_name"
                label="display_name"
              >
              </v-select>

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- monthEnd -->
          <validation-provider
            #default="validationContext"
            name="monthEnd"
            rules="required"
          >
            <b-form-group :label="lang('t_monthEnd')" label-for="monthEnd">
              <v-select
                v-model="currentData.month_end"
                :options="months"
                :placeholder="lang('t_monthEnd')"
                :reduce="(val) => val.internal_name"
                value="internal_name"
                label="display_name"
              >
              </v-select>

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- startingDayOfMonth -->
          <validation-provider
            #default="validationContext"
            name="startingDayOfMonth"
            rules="required"
          >
            <b-form-group
              :label="lang('t_startingDayOfMonth')"
              label-for="startingDayOfMonth"
            >
              <v-select
                v-model="currentData.month_start_day"
                :options="month_days"
                :placeholder="lang('t_startingDayOfMonth')"
              >
              </v-select>

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- endingDayOfMonth -->
          <validation-provider
            #default="validationContext"
            name="endingDayOfMonth"
            rules="required"
          >
            <b-form-group
              :label="lang('t_endingDayOfMonth')"
              label-for="endingDayOfMonth"
            >
              <v-select
                v-model="currentData.month_end_day"
                :options="month_days"
                :placeholder="lang('t_endingDayOfMonth')"
              >
              </v-select>

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{lang('t_add')}}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{lang('t_cancel')}}
            </b-button>
          </div>
          -->
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInvalidFeedback,
  BFormCheckbox,
  BFormTimepicker,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BModal,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import useList from "./useList";
import storeModule from "../storeModule";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, alphaNum, email, password } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";

import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    // UsersListFilters,
    BForm,
    BFormGroup,
    BFormInvalidFeedback,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormTimepicker,

    vSelect,
  },
  data() {
    return {
      lang: _l(window.localStorage.getItem("language") || "tr"),
      is_progress: false,
      isEdit: false,
      currentData: {
        _id: "",
        domain: "",
        end_time: "18:00",
        end_day: { index: 5, key: "fri", value: globalThis._lang("t_friday") },
        start_time: "09:00",
        start_day: {
          index: 1,
          key: "mon",
          value: globalThis._lang("t_monday"),
        },
        internal_name: "",
        display_name: "",
        month_start: "",
        month_end: "",
        month_start_day: "",
        month_end_day: "",
      },
      records: [],
      time_conditions: [],
      tableColumns: [
        {
          key: "display_name",
          label: globalThis._lang("t_timeConditionName"),
          sortable: true,
        },
        {
          key: "start_day.value",
          label: globalThis._lang("t_startDay"),
          sortable: true,
        },
        {
          key: "start_time",
          label: globalThis._lang("t_startTime"),
          sortable: true,
        },
        {
          key: "end_day.value",
          label: globalThis._lang("t_endDay"),
          sortable: true,
        },
        {
          key: "end_time",
          label: globalThis._lang("t_endTime"),
          sortable: true,
        },
        {
          key: "month_start_day",
          label: globalThis._lang("t_monthStartDay"),
          sortable: true,
        },
        {
          key: "month_end_day",
          label: globalThis._lang("t_monthEndDay"),
          sortable: true,
        },
        {
          key: "actions",
          label: globalThis._lang("t_actions"),
          sortable: false,
        },
      ],
      selected_time_condition: {},
      month_days: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
      ],
      days: [
        { index: 1, key: "mon", value: globalThis._lang("t_monday") },
        { index: 2, key: "tue", value: globalThis._lang("t_tuesday") },
        { index: 3, key: "wed", value: globalThis._lang("t_wednesday") },
        { index: 4, key: "thu", value: globalThis._lang("t_thursday") },
        { index: 5, key: "fri", value: globalThis._lang("t_friday") },
        { index: 6, key: "sat", value: globalThis._lang("t_saturday") },
        { index: 7, key: "sun", value: globalThis._lang("t_sunday") },
      ],
      months: [
        {
          index: 1,
          internal_name: "1",
          display_name: globalThis._lang("t_january"),
        },
        {
          index: 2,
          internal_name: "2",
          display_name: globalThis._lang("t_february"),
        },
        {
          index: 3,
          internal_name: "3",
          display_name: globalThis._lang("t_march"),
        },
        {
          index: 4,
          internal_name: "4",
          display_name: globalThis._lang("t_april"),
        },
        {
          index: 5,
          internal_name: "5",
          display_name: globalThis._lang("t_may"),
        },
        {
          index: 6,
          internal_name: "6",
          display_name: globalThis._lang("t_june"),
        },
        {
          index: 7,
          internal_name: "7",
          display_name: globalThis._lang("t_july"),
        },
        {
          index: 8,
          internal_name: "8",
          display_name: globalThis._lang("t_august"),
        },
        {
          index: 9,
          internal_name: "9",
          display_name: globalThis._lang("t_september"),
        },
        {
          index: 10,
          internal_name: "10",
          display_name: globalThis._lang("t_october"),
        },
        {
          index: 11,
          internal_name: "11",
          display_name: globalThis._lang("t_novenber"),
        },
        {
          index: 12,
          internal_name: "12",
          display_name: globalThis._lang("t_december"),
        },
      ],
      modal_record_details: false,
      required,
      alphaNum,
      email,
      password,
    };
  },
  mounted: function () {
    this.get_records();
  },
  methods: {
    get_records: async function () {
      this.is_progress = true;
      var response = (await this.$http_in.get(`/smgr/v1/TimeCondition `)).data;
      console.log("time_conditions", response);

      this.time_conditions = response;
      this.records = response;
      this.is_progress = false;
      // for (const item of response) {
      //   this.records.push(item.tc_object);

      // }
      console.log("rec", this.records);
    },

    check_data() {
      if (
        this.currentData.end_time == "" ||
        this.currentData.end_day == "" ||
        this.currentData.start_time == "" ||
        this.currentData.start_day == "" ||
        this.currentData.display_name == ""
      ) {
        return true;
      }
      return false;
    },
    add_record: async function () {
      if (this.check_data()) {
        this.$swal({
          title: globalThis._lang("t_warning"),
          text: globalThis._lang("t_pleaseDoNotLeaveAnyBlankFields"),
          icon: "warning",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
        return;
      }

      this.is_progress = true;
      var response;
      if (!this.isEdit) {
        response = (
          await this.$http_in.post(`/smgr/v1/TimeCondition`, {
            data: JSON.stringify(this.currentData),
          })
        ).data;
      } else {
        var is_type_changed = false;
        if (this.currentData.type != this.currentPage.register_type) {
          is_type_changed = true;
        }
        response = (
          await this.$http_in.put(`/smgr/v1/TimeCondition`, {
            data: JSON.stringify(this.currentData),
            type_changed: is_type_changed,
          })
        ).data;
      }
      this.is_progress = false;
      if (response && response.acknowledged) {
        this.$swal({
          title: globalThis._lang("t_processSuccess"),
          text: globalThis._lang("t_hasBeenCreatedSuccessfully"),
          icon: "success",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      } else {
        this.$swal({
          title: globalThis._lang("t_error"),
          text: globalThis._lang("t_AProblemOccurredwhileProcessing"),
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      }

      this.reset_record();
      this.get_records();
    },
    delete_record: async function () {
      this.is_progress = true;
      var response = (
        await this.$http_in.delete(
          `/smgr/v1/TimeCondition/${this.currentData._id}`
        )
      ).data;
      this.is_progress = false;

      if (response && response.acknowledged) {
        this.$swal({
          icon: "success",
          title: this.lang("t_processSuccess"),
          text: this.lang("t_customersHaveBeenSuccessfullyDeleted"),
          confirmButtonText: this.lang("t_ok"),
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
      }

      this.reset_record();
      this.get_records();
    },
    reset_record() {
      this.isEdit = false;
      this.currentData = {
        _id: "",
        domain: "",
        end_time: "18:00",
        end_day: { index: 5, key: "fri", value: globalThis._lang("t_friday") },
        start_time: "09:00",
        start_day: {
          index: 1,
          key: "mon",
          value: globalThis._lang("t_monday"),
        },
        internal_name: "",
        display_name: "",
        month_start: "",
        month_end: "",
        month_start_day: "",
        month_end_day: "",
      };
    },
    updateSelected(item) {
      this.selected_time_condition = item;
      this.currentData = {
        ...item,
      };
      console.log("item", item);
      this.isEdit = true;
      //this.isAddNewUserSidebarActive = true;
      this.modal_record_details = true;
    },
    confirmText(item) {
      this.currentData = item;
      this.$swal({
        title: this.lang("t_deleteRecord"),
        text: this.lang("t_areYouSureYouWantToDeleteTheSelectedRecord"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.lang("t_yes"),
        cancelButtonText: this.lang("t_cancel"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.delete_record();
        }
      });
    },
    deleteRecord: async function () {
      // Vue.$toast('Kayıt silme.');

      this.fetchData();
    },
  },

  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-user";
    // const USER_APP_STORE_MODULE_NAME = 'app-user'
    const toast = Vue.$toast;

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, storeModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    const blankUserData = {
      fullName: "",
      username: "",
      email: "",
      role: null,
      currentPlan: null,
      company: "",
      contact: "",
    };

    const resetuserData = () => {
      currentData.value = JSON.parse(JSON.stringify(blankUserData));
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetuserData);

    const isAddNewUserSidebarActive = ref(false);

    const dayOptions = [
      { label: globalThis._lang("t_sunday"), value: "0" },
      { label: globalThis._lang("t_monday"), value: "1" },
      { label: globalThis._lang("t_tuesday"), value: "2" },
      { label: globalThis._lang("t_wendsday"), value: "3" },
      { label: globalThis._lang("t_thursday"), value: "4" },
      { label: globalThis._lang("t_friday"), value: "5" },
      { label: globalThis._lang("t_saturday"), value: "6" },
    ];
    const monthOptions = [
      { label: globalThis._lang("t_january"), value: "1" },
      { label: globalThis._lang("t_february"), value: "2" },
      { label: globalThis._lang("t_march"), value: "3" },
      { label: globalThis._lang("t_april"), value: "4" },
      { label: globalThis._lang("t_may"), value: "5" },
      { label: globalThis._lang("t_june"), value: "6" },
      { label: globalThis._lang("t_july"), value: "7" },
      { label: globalThis._lang("t_august"), value: "8" },
      { label: globalThis._lang("t_september"), value: "9" },
      { label: globalThis._lang("t_october"), value: "10" },
      { label: globalThis._lang("t_novenber"), value: "11" },
      { label: globalThis._lang("t_december"), value: "12" },
    ];
    const numberOptions = [
      { label: "1", value: "1" },
      { label: "2", value: "2" },
      { label: "3", value: "3" },
      { label: "4", value: "4" },
      { label: "5", value: "5" },
      { label: "6", value: "6" },
      { label: "7", value: "7" },
      { label: "8", value: "8" },
      { label: "9", value: "9" },
      { label: "10", value: "10" },
      { label: "11", value: "11" },
      { label: "12", value: "12" },
      { label: "13", value: "13" },
      { label: "14", value: "14" },
      { label: "15", value: "15" },
      { label: "16", value: "16" },
      { label: "17", value: "17" },
      { label: "18", value: "18" },
      { label: "19", value: "19" },
      { label: "20", value: "20" },
      { label: "21", value: "21" },
      { label: "22", value: "22" },
      { label: "23", value: "23" },
      { label: "24", value: "24" },
      { label: "25", value: "25" },
      { label: "26", value: "26" },
      { label: "27", value: "27" },
      { label: "28", value: "28" },
      { label: "29", value: "29" },
      { label: "30", value: "30" },
      { label: "31", value: "31" },
    ];
    const projectOptions = [
      { label: "Abc", value: "1" },
      { label: "Proje2", value: "2" },
      { label: "Proje3", value: "3" },
      { label: "Proje4", value: "4" },
    ];
    const profileOptions = [
      { label: "Admin", value: "1" },
      { label: "Profil2", value: "2" },
      { label: "Profil3", value: "3" },
      { label: "Profil4", value: "4" },
    ];

    const roleOptions = [
      { label: "Admin", value: "admin" },
      { label: "Author", value: "author" },
      { label: "Editor", value: "editor" },
      { label: "Maintainer", value: "maintainer" },
      { label: "Subscriber", value: "subscriber" },
    ];

    const planOptions = [
      { label: "Basic", value: "basic" },
      { label: "Company", value: "company" },
      { label: "Enterprise", value: "enterprise" },
      { label: "Team", value: "team" },
    ];

    const statusOptions = [
      { label: "Pending", value: "pending" },
      { label: "Active", value: "active" },
      { label: "Inactive", value: "inactive" },
    ];

    var {
      fetchData,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    } = useList();

    return {
      // Sidebar
      isAddNewUserSidebarActive,

      fetchData,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      dayOptions,
      monthOptions,
      numberOptions,
      projectOptions,
      profileOptions,
      roleOptions,
      planOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,

      toast,
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
