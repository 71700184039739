<template>
  <div>
    <b-custom-spinner :is_progress="is_progress"></b-custom-spinner>
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ lang("t_show") }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ lang("t_entries") }}</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                :placeholder="lang('t_search')"
              />
              <b-button
                class="mr-1"
                variant="success"
                @click="
                  modal_excel = true;
                  isEdit = false;
                  reset_record();
                "
              >
                <span class="text-nowrap">{{ lang("t_importRecords") }}</span>
              </b-button>
              <b-button
                variant="primary"
                @click="
                  modal_record_details = true;
                  isEdit = false;
                  reset_record();
                "
              >
                <span class="text-nowrap">{{ lang("t_addRecord") }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        style="min-height: 300px"
        ref="refUserListTable"
        class="position-relative"
        :items="records"
        responsive
        :fields="tableColumns"
        primary-key="internal_name"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="lang('t_noRecord')"
        :sort-desc.sync="isSortDirDesc"
        :filter="searchQuery"
      >
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item @click="updateSelected(data.item)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">{{ lang("t_edit") }}</span>
            </b-dropdown-item>

            <b-dropdown-item @click="confirmText(data.item)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">{{ lang("t_delete") }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >{{ lang("t_showing") }} {{ records.length }}
              {{ lang("t_entries") }}</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-modal
      no-close-on-esc
      no-close-on-backdrop
      :title="isEdit ? lang('t_edit') : lang('t_add')"
      centered
      v-model="modal_record_details"
      :ok-title="lang('t_save')"
      :cancel-title="lang('t_cancel')"
      @ok="add_record"
    >
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="lineName"
            rules="required"
          >
            <b-form-group :label="lang('t_lineName')" label-for="lineName">
              <b-form-input
                id="lineName"
                v-model="currentData.display_name"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Surname -->
          <validation-provider
            #default="validationContext"
            name="type"
            rules="required"
          >
            <b-form-group :label="lang('t_type')" label-for="type">
              <v-select
                v-model="currentData.register_type"
                :options="register_types"
                :placeholder="lang('t_type')"
                :reduce="(val) => val.key"
                value="key"
                label="value"
                :state="getValidationState(validationContext)"
              ></v-select>

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- username -->
          <validation-provider
            #default="validationContext"
            name="type"
            rules="required"
          >
            <b-form-group
              v-if="currentData.register_type == 'reg'"
              :label="lang('t_userName')"
              label-for="username"
            >
              <b-form-input
                id="username"
                v-model="currentData.username"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- password -->
          <validation-provider
            #default="validationContext"
            name="password"
            rules="required"
          >
            <b-form-group
              v-if="currentData.register_type == 'reg'"
              :label="lang('t_password')"
              label-for="password"
            >
              <b-form-input
                id="password"
                v-model="currentData.password"
                :state="getValidationState(validationContext)"
                trim
                type="password"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Username -->
          <validation-provider
            #default="validationContext"
            name="outboundCID"
            rules="required"
          >
            <b-form-group
              :label="lang('t_outboundCID')"
              label-for="outboundCID"
            >
              <b-form-input
                id="outboundCID"
                v-model="currentData.caller_number"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Password -->
          <validation-provider
            #default="validationContext"
            name="prefix"
            rules="required"
          >
            <b-form-group :label="lang('t_prefix')" label-for="prefix">
              <b-form-input
                id="prefix"
                v-model="currentData.prefix"
                trim
                :state="getValidationState(validationContext)"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Company -->
          <validation-provider
            #default="validationContext"
            name="transferCID"
            rules="required"
          >
            <b-form-group
              :label="lang('t_transferCID')"
              label-for="transferCID"
            >
              <b-form-input
                id="transferCID"
                v-model="currentData.external_routing_number"
                trim
                :state="getValidationState(validationContext)"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- User Role -->
          <b-row>
            <b-col cols="6">
              <validation-provider
                #default="validationContext"
                name="project"
                rules="required"
              >
                <b-form-group
                  :label="lang('t_inboundCallLimit')"
                  label-for="inboundCallLimit"
                >
                  <b-form-input
                    id="inboundCallLimit"
                    v-model="currentData.inbound_call_limit"
                    trim
                    type="number"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="6">
              <validation-provider
                #default="validationContext"
                name="outboundCallLimit"
                rules="required"
              >
                <b-form-group
                  :label="lang('t_outboundCallLimit')"
                  label-for="outboundCallLimit"
                  :state="getValidationState(validationContext)"
                >
                  <b-form-input
                    id="outboundCallLimit"
                    v-model="currentData.outbound_call_limit"
                    trim
                    :state="getValidationState(validationContext)"
                    type="number"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <validation-provider
                #default="validationContext"
                name="ip"
                rules="required"
              >
                <b-form-group
                  :label="lang('t_ip')"
                  label-for="ip"
                  :state="getValidationState(validationContext)"
                >
                  <b-form-input
                    id="ip"
                    v-model="currentData.ip"
                    trim
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="6">
              <validation-provider
                #default="validationContext"
                name="port"
                rules="required"
              >
                <b-form-group
                  :label="lang('t_port')"
                  label-for="port"
                  :state="getValidationState(validationContext)"
                >
                  <b-form-input
                    id="port"
                    v-model="currentData.port"
                    trim
                    type="number"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <validation-provider
                #default="validationContext"
                name="transportType"
                rules="required"
              >
                <b-form-group
                  :label="lang('t_transportType')"
                  label-for="transportType"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="currentData.transport_type"
                    :options="transport_types"
                    :placeholder="lang('t_transportType')"
                    :reduce="(val) => val.key"
                    label="value"
                    value="key"
                  ></v-select>
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="6">
              <validation-provider
                #default="validationContext"
                name="o"
                rules="required"
              >
                <b-form-group
                  :label="lang('t_codec')"
                  label-for="codec"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="currentData.codec"
                    :options="codec_types"
                    :placeholder="lang('t_codec')"
                    :reduce="(val) => val.key"
                    label="value"
                    value="key"
                    multiple
                  ></v-select>
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <!-- Plan -->

          <!-- Form Actions
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{lang('t_add')}}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{lang('t_cancel')}}
            </b-button>
          </div>
         -->
        </b-form>
      </validation-observer>
    </b-modal>
    <b-modal
      no-close-on-esc
      no-close-on-backdrop
      @ok="read_data"
      v-model="modal_excel"
      :title="lang('t_importRecords')"
      centered
    >
      <b-row align-h="end">
        <b-button
          href="template_examples/voyce_line_import.xlsx"
          :exact="true"
          size="sm"
          variant="outline-success"
          >{{ lang("t_exampleFile") }}</b-button
        >
      </b-row>
      <b-row class="mt-1">
        <b-form-file
          :browse-text="lang('t_file')"
          v-model="files"
          :placeholder="lang('t_uploadFileOnClick')"
          :drop-placeholder="lang('t_uploadFile')"
          no-drop
          required
          accept=".xlsx, .xls"
        />
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInvalidFeedback,
  BFormCheckbox,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BModal,
  BFormFile,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import useList from "./useList";
import storeModule from "../storeModule";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, alphaNum, email, password } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";

import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    // UsersListFilters,
    BFormFile,
    BForm,
    BFormGroup,
    BFormInvalidFeedback,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  data() {
    return {
      lang: _l(window.localStorage.getItem("language") || "tr"),
      is_progress: false,
      isEdit: false,
      modal_excel: false,
      currentData: {
        internal_name: "",
        display_name: "",
        register_type: "ip",
        ip: "",
        port: "5060",
        codec: ["g729", "ulaw", "alaw"],
        transport_type: "udp",
        username: "",
        password: "",
        inbound_call_limit: "0",
        outbound_call_limit: "0",
        caller_number: "",
        external_routing_number: "",
        prefix: "",
      },
      modal_record_details: false,
      required,
      alphaNum,
      email,
      password,
      files: {},
      tableColumns: [
        {
          key: "display_name",
          label: globalThis._lang("t_lineName"),
          sortable: true,
        },
        {
          key: "register_type",
          label: globalThis._lang("t_type"),
          sortable: true,
        },
        {
          key: "outboundCID",
          label: globalThis._lang("t_outboundCID"),
          sortable: true,
        },
        { key: "ip", label: globalThis._lang("t_ip"), sortable: true },
        { key: "port", label: globalThis._lang("t_port"), sortable: true },
        { key: "codec", label: globalThis._lang("t_codec"), sortable: true },
        {
          key: "actions",
          label: globalThis._lang("t_actions"),
          sortable: false,
        },
      ],
      records: [],
      register_types: [
        {
          key: "ip",
          value: "IP",
        },
        {
          key: "reg",
          value: "REGISTER",
        },
      ],
      codec_types: [
        {
          key: "g729",
          value: "G729",
        },
        {
          key: "ulaw",
          value: "ULAW",
        },
        {
          key: "alaw",
          value: "ALAW",
        },
      ],
      transport_types: [
        {
          key: "tcp",
          value: "TCP",
        },
        {
          key: "udp",
          value: "UDP",
        },
      ],
    };
  },
  mounted: function () {
    this.get_records();
  },
  methods: {
    async read_data() {
      this.progress = true;
      const reader = new FileReader();
      reader.onload = (e) => {
        const bstr = e.target.result;
        const wb = globalThis.XLSX.read(bstr, { type: "binary" });

        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = globalThis.XLSX.utils.sheet_to_json(ws, {
          header: 1,
          raw: false,
        });
        var _data = this.xlsxToJson(data);

        for (const item of _data) {
          this.currentData = {
            id: "",
            internal_name: "",
            display_name: item["display_name"],
            register_type: item["register_type"],
            ip: item["ip"],
            port: item["port"],
            codec: item["codec"],
            prefix: item["prefix"],
            transport_type: item["transport_type"],
            username: item["username"],
            password: item["password"],
            inbound_call_limit: item["inbound_call_limit"],
            outbound_call_limit: item["outbound_call_limit"],
            caller_number: item["caller_number"],
            external_routing_number: item["external_routing_number"],
          };
          this.add_record();
        }
        this.progress = false;
        this.modal_excel = false;
      };
      reader.readAsBinaryString(this.files);
    },

    xlsxToJson(data) {
      var jsonData = [];
      let headers = data[0];
      let _data = data.slice(1);
      let objectHolder = [];
      for (let row of _data) {
        let _row = [];
        for (var i = 0; i < row.length; i++) {
          _row.push([headers[i], row[i]]);
        }
        jsonData.push(Object.fromEntries(_row));
      }
      return jsonData;
    },
    get_records: async function () {
      this.is_progress = true;
      var response = (await this.$http_in.get(`smgr/v1/Line`)).data;
      console.log("Line", response);
      this.records = response;
      this.is_progress = false;
    },

    check_data() {
      if (
        this.currentData.display_name == "" ||
        this.currentData.ip == "" ||
        this.currentData.port == "" ||
        this.currentData.codec == "" ||
        this.currentData.register_type == "" ||
        this.currentData.caller_number == ""
      ) {
        return true;
      }
      return false;
    },
    add_record: async function () {
      if (this.check_data()) {
        this.$swal({
          title: globalThis._lang("t_warning"),
          text: globalThis._lang("t_pleaseDoNotLeaveAnyBlankFields"),
          icon: "warning",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
        return;
      }
      this.is_progress = true;
      var response;
      if (!this.isEdit) {
        response = (
          await this.$http_in.post(`smgr/v1/Line`, {
            data: JSON.stringify(this.currentData),
          })
        ).data;
      } else {
        var is_type_changed = false;
        if (this.currentData.type != this.currentData.register_type) {
          is_type_changed = true;
        }
        response = (
          await this.$http_in.put(`smgr/v1/Line`, {
            data: JSON.stringify(this.currentData),
            type_changed: is_type_changed,
          })
        ).data;
      }

      this.is_progress = false;
      if (response && response[0].acknowledged) {
        this.$bvToast.toast(`${globalThis._lang("t_lineChangeInfo")}`, {
          title: `${globalThis._lang("t_info")}`,
          toaster: "b-toaster-top-right",
          solid: true,
          variant: "warning",
          appendToast: false,
          variant: "success",
        });

        this.$swal({
          title: globalThis._lang("t_processSuccess"),
          text: globalThis._lang("t_hasBeenCreatedSuccessfully"),
          icon: "success",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      } else {
        this.$swal({
          title: globalThis._lang("t_error"),
          text: globalThis._lang("t_AProblemOccurredwhileProcessing"),
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      }

      this.reset_record();
      this.get_records();
    },
    delete_record: async function () {
      this.is_progress = true;
      var response = (
        await this.$http_in.delete(
          `smgr/v1/Line/${this.currentData.internal_name}`,
          {}
        )
      ).data;
      this.is_progress = false;

      if (response && response[0].acknowledged) {
        this.$swal({
          icon: "success",
          title: this.lang("t_processSuccess"),
          text: this.lang("t_customersHaveBeenSuccessfullyDeleted"),
          confirmButtonText: this.lang("t_ok"),
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
      }

      this.reset_record();
      this.get_records();
    },
    reset_record() {
      this.isEdit = false;
      this.currentData = {
        internal_name: "",
        display_name: "",
        register_type: "ip",
        ip: "",
        port: "5060",
        codec: ["g729", "ulaw", "alaw"],
        transport_type: "udp",
        username: "",
        password: "",
        inbound_call_limit: "0",
        outbound_call_limit: "0",
        caller_number: "",
        external_routing_number: "",
      };
    },
    updateSelected(item) {
      this.currentData = item;
      console.log("item", item);
      this.isEdit = true;
      this.isAddNewUserSidebarActive = true;
      this.modal_record_details = true;
    },
    confirmText(item) {
      this.currentData = item;
      this.$swal({
        title: this.lang("t_deleteRecord"),
        text: this.lang("t_areYouSureYouWantToDeleteTheSelectedRecord"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.lang("t_yes"),
        cancelButtonText: this.lang("t_cancel"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.delete_record();
        }
      });
    },
    deleteRecord: async function () {
      // Vue.$toast('Kayıt silme.');

      this.fetchData();
    },
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-user";
    // const USER_APP_STORE_MODULE_NAME = 'app-user'
    const toast = Vue.$toast;

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, storeModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    const blankUserData = {
      fullName: "",
      username: "",
      email: "",
      role: null,
      currentPlan: null,
      company: "",
      contact: "",
    };

    const resetuserData = () => {
      currentData.value = JSON.parse(JSON.stringify(blankUserData));
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetuserData);

    const isAddNewUserSidebarActive = ref(false);

    const extensOptions = [
      { label: "123", value: "123" },
      { label: "456", value: "456" },
      { label: "9999", value: "9999" },
      { label: "654", value: "654" },
    ];
    const projectOptions = [
      { label: "Abc", value: "1" },
      { label: "Proje2", value: "2" },
      { label: "Proje3", value: "3" },
      { label: "Proje4", value: "4" },
    ];
    const profileOptions = [
      { label: "Admin", value: "1" },
      { label: "Profil2", value: "2" },
      { label: "Profil3", value: "3" },
      { label: "Profil4", value: "4" },
    ];

    const roleOptions = [
      { label: "Admin", value: "admin" },
      { label: "Author", value: "author" },
      { label: "Editor", value: "editor" },
      { label: "Maintainer", value: "maintainer" },
      { label: "Subscriber", value: "subscriber" },
    ];

    const planOptions = [
      { label: "Basic", value: "basic" },
      { label: "Company", value: "company" },
      { label: "Enterprise", value: "enterprise" },
      { label: "Team", value: "team" },
    ];

    const statusOptions = [
      { label: "Pending", value: "pending" },
      { label: "Active", value: "active" },
      { label: "Inactive", value: "inactive" },
    ];

    var {
      fetchData,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    } = useList();

    return {
      // Sidebar
      isAddNewUserSidebarActive,

      fetchData,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      extensOptions,
      projectOptions,
      profileOptions,
      roleOptions,
      planOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,

      toast,
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
