<template>
  <b-container fluid class="px-0">
    <b-row class="mb-1 px-0 mx-0">
      <b-button
        v-if="menu.includes('lines')"
        style="width: 130px"
        class=""
        :variant="
          selectedButton != 'lines' ? 'outline-primary' : 'relief-primary'
        "
        pill
        @click="selectedButton = 'lines'"
      >
        {{ lang("t_lines") }}
      </b-button>
      <b-button
        v-if="menu.includes('line_groups')"
        style="width: 130px"
        class="ml-50"
        :variant="
          selectedButton != 'line_groups' ? 'outline-primary' : 'relief-primary'
        "
        pill
        @click="selectedButton = 'line_groups'"
      >
        {{ lang("t_lineGroup") }}
      </b-button>
      <b-button
        v-if="menu.includes('dial_plans')"
        style="width: 130px"
        class="ml-50"
        :variant="
          selectedButton != 'dial_plans' ? 'outline-primary' : 'relief-primary'
        "
        pill
        @click="selectedButton = 'dial_plans'"
      >
        {{ lang("t_dialPlans") }}
      </b-button>
      <!-- v-ripple.400="'rgba(186, 191, 199, 0.15)'" -->

      <b-button
        v-if="menu.includes('ivr')"
        style="width: 130px"
        class="ml-50"
        :variant="
          selectedButton != 'ivr' ? 'outline-primary' : 'relief-primary'
        "
        pill
        @click="selectedButton = 'ivr'"
      >
        {{ lang("t_IVR") }}
      </b-button>
      <b-button
        v-if="menu.includes('v_ivr')"
        style="width: 130px"
        class="ml-50"
        :variant="
          selectedButton != 'v_ivr' ? 'outline-primary' : 'relief-primary'
        "
        pill
        @click="selectedButton = 'v_ivr'"
      >
        {{ lang("t_vIVR") }}
      </b-button>
      <b-button
        v-if="menu.includes('survey')"
        style="width: 130px"
        class="ml-50"
        :variant="
          selectedButton != 'survey' ? 'outline-primary' : 'relief-primary'
        "
        pill
        @click="selectedButton = 'survey'"
      >
        {{ lang("t_survey") }}
      </b-button>
      <b-button
        v-if="menu.includes('announcements')"
        style="width: 130px"
        class="ml-50"
        :variant="
          selectedButton != 'announcements'
            ? 'outline-primary'
            : 'relief-primary'
        "
        pill
        @click="selectedButton = 'announcements'"
      >
        {{ lang("t_announcements") }}
      </b-button>
      <b-button
        v-if="menu.includes('inbound_routes')"
        style="width: 130px"
        class="ml-50"
        :variant="
          selectedButton != 'inbound_routes'
            ? 'outline-primary'
            : 'relief-primary'
        "
        pill
        @click="selectedButton = 'inbound_routes'"
      >
        {{ lang("t_routes") }}
      </b-button>
      <b-button
        v-if="menu.includes('time_conditions')"
        style="width: 130px"
        class="ml-50"
        :variant="
          selectedButton != 'time_conditions'
            ? 'outline-primary'
            : 'relief-primary'
        "
        pill
        @click="selectedButton = 'time_conditions'"
      >
        {{ lang("t_timeCondition") }}
      </b-button>
    </b-row>
    <announcements v-if="selectedButton == 'announcements'"></announcements>
    <inbound-routes v-if="selectedButton == 'inbound_routes'"></inbound-routes>
    <line-group v-if="selectedButton == 'line_groups'"></line-group>
    <ivr v-if="selectedButton == 'ivr'"></ivr>
    <variable-ivr v-if="selectedButton == 'v_ivr'"></variable-ivr>
    <survey v-if="selectedButton == 'survey'"></survey>
    <lines v-if="selectedButton == 'lines'"></lines>
    <time-conditions
      v-if="selectedButton == 'time_conditions'"
    ></time-conditions>

    <dial-plans v-if="selectedButton == 'dial_plans'"></dial-plans>
  </b-container>
</template>

<script>
import { BContainer, BFormRadioGroup, BFormGroup, BRow, BCol, BCard, BCardText, BLink, BButton } from 'bootstrap-vue'
// import SmsSender from './SmsSender.vue'
import Announcements from './Announcements/list/List.vue'
import InboundRoutes from './InboundRoutes/list/List.vue'
import DialPlans from './DialPlans/list/List.vue'
import ivr from './IVR/IVR.vue'
import VariableIvr from './Variable_IVR/IVR.vue'
import Survey from './Survey/Survey.vue'
import Lines from './Lines/list/List.vue'
import TimeConditions from './TimeConditions/list/List.vue'
import LineGroup from './LineGroup/LineGroup.vue'


export default {
  data() {
    return {
      lang: _l(window.localStorage.getItem('language') || "tr"),
      selectedButton: 'lines',
      menu: [],
    }
  },
  components: {
    BContainer,
    Announcements,
    InboundRoutes,
    ivr,
    VariableIvr,
    Survey,
    Lines,
    TimeConditions,
    DialPlans,
    LineGroup,
    BFormRadioGroup,
    BFormGroup,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BButton,
  },
  mounted: async function () {
    this.menu = globalThis.permissions["menu"];
    if (this.menu.includes('lines')) {
      this.selectedButton = 'lines';
    }
  }
}
</script>

<style>
</style>
