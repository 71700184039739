import { Node } from "@baklavajs/core";

export class SurveyNode extends Node {
  twoColumn = true;

  constructor() {
    super();
    this.type = "SurveyNode";
    this.name = "Survey";
    this.addOption("Announcement", "SelectOption", '', undefined, {
      items: globalThis.IVRElements.AnnouncementNode,
      displayName: "Announcement",
    })
    this.addOption("Survey", "SelectOption", "", undefined, {
      items: []
    })
    this.addInputInterface("In");
    this.addOutputInterface("Next");
  }
}
